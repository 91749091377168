'use-client';
import * as React from 'react';
import type { MeshContainerProps } from '@wix/thunderbolt-components-native';
import type { VideoBoxProps, VideoBoxActions } from '../../../VideoBox.types';
import MeshContainer from '../../../../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';
import VideoBoxCommon from '../../VideoBoxCommon';

const VideoBox: React.ForwardRefRenderFunction<
  VideoBoxActions,
  VideoBoxProps
> = (props: VideoBoxProps, compRef) => {
  const mediaControlsContainer = (
    <MeshContainer id={props.id} {...(props.meshProps as MeshContainerProps)}>
      {props.children}
    </MeshContainer>
  );

  return (
    <VideoBoxCommon
      {...props}
      compRef={compRef}
      mediaControls={mediaControlsContainer}
    />
  );
};

export default React.forwardRef(VideoBox);
